
var testimonialSlide = new Swiper('.testimonialSlide', {
    disableOnInteraction: false,
    loop: true,
    speed: 1000,
    autoplay: {
        delay: 5000
    },
    effect: 'fade',
    fadeEffect: {
    crossFade: true
    },
    // navigation: {
    //     nextEl: '.testimonialSlide .hs-next',
    //     prevEl: '.testimonialSlide .hs-prev',
    // },
    pagination: {
        el: '.testimonialSlide .sldot',
        clickable: true
    }
});


$(".testimonialSlide").hover(function() {
    testimonialSlide.autoplay.stop();
}, function() {
    testimonialSlide.autoplay.start();
});



var case1Slide = new Swiper('.case1Slide', {
    disableOnInteraction: false,
    loop: true,
    speed: 1000,
    autoplay: {
        delay: 5000
    },
    navigation: {
        nextEl: '.case1Slide .hs-next',
        prevEl: '.case1Slide .hs-prev',
    },
    pagination: {
        el: '.case1Slide .sldot',
        clickable: true
    }
});


$(".case1Slide").hover(function() {
    case1Slide.autoplay.stop();
}, function() {
    case1Slide.autoplay.start();
});




jQuery(document).ready(function($){

/// Mouse scroll

$(".section").each(function(index, el) {
  var $offs = $(this).offset().top;
  $(this).attr("data-offset", $offs );
});

$(".mouse-wrap").each(function(index, el) {
     var $topArrow = $(this).find(".flaticon-arrows");
      var $bottomArrow = $(this).find(".flaticon-arrows-1");

    $topArrow.on('click', function(event) {
     var dataPrevOffset =  $topArrow.parents(".mouse-outer").prev(".section").data("offset");
     $("html,body").animate({"scrollTop": dataPrevOffset}, 1000);
     console.log(dataPrevOffset);
    });

    $bottomArrow.on('click', function(event) {
     var dataNextOffset =  $bottomArrow.parents(".mouse-outer").next(".section").data("offset");
     $("html,body").animate({"scrollTop": dataNextOffset}, 1000);
     console.log(dataNextOffset);
    });

});




function marginVid(){
  var winw = $(window).width();
  var conW = $(".herovid").parents(".container").width();
  var marginW = ((winw - conW) / 2) - 32;
  return marginW;
}


$(".herovid").css('margin-left',  -marginVid()+"px");

$(window).resize(function(event) {
  $(".herovid").css('margin-left',  -marginVid()+"px");
});


$.fn.extend({
  animateCss: function(animationName, callback) {
    var animationEnd = (function(el) {
      var animations = {
        animation: 'animationend',
        OAnimation: 'oAnimationEnd',
        MozAnimation: 'mozAnimationEnd',
        WebkitAnimation: 'webkitAnimationEnd',
      };

      for (var t in animations) {
        if (el.style[t] !== undefined) {
          return animations[t];
        }
      }
    })(document.createElement('span'));

    this.addClass('animm ' + animationName).one(animationEnd, function() {
      $(this).removeClass('animm ' + animationName);

      if (typeof callback === 'function') callback();
    });

    return this;
  },
});

setInterval(function(){
  $(".m-top").animateCss('animtop');
  $(".m-bottom").animateCss('animbottom');
  //$(".track").animateCss('trackanim');
}, 1300);



$(".srv-wrap li").each(function(index, el) {
  $(this).css('transition-delay', "0."+index+'s');
});


$("[data-anim]").each(function(index, el) {
        var $elm = $(this);
        var $offset =  $elm.data("anim-offset");
        $elmWatcher = scrollMonitor.create($elm, $offset);

        $elmWatcher.enterViewport(function() {
            $elm.css("animation-delay",+$elm.data("anim-delay")+"s" ).addClass("animated "+ $elm.data("anim")).css("visibility","visible");

        });



    });




function arowupdown(){
  var halfH = $(window).height() / 2;
$(".bg-primary").each(function(index, el) {

  var myElement = $(this);

  var elementWatcher = scrollMonitor.create( myElement, -halfH );

      elementWatcher.enterViewport(function() {
          // console.log( 'I have entered the viewport' );
          $(".up-down span").css('background-color', '#fff');
      });
      elementWatcher.exitViewport(function() {
          // console.log( 'I have left the viewport' );
          $(".up-down span").css('background-color', '#f9f030');
      });



});

}

arowupdown();

$(window).on('resize', function(event) {
  arowupdown();
  console.log("resiziing")
});


$(".scrollto").on("click", function(){
    $("html,body").animate({scrollTop: $("#aboutus").position().top - 80}, 1000);
});

if($('.slideout-menu').hasClass('slideout-menu')){

var slideout = new Slideout({
        'panel': document.getElementById('panel'),
        'menu': document.getElementById('menumob'),
        'padding': 256,
        'tolerance': 70
    });


var fixed = document.querySelector('.headermob');

slideout.on('translate', function(translated) {
  fixed.style.transform = 'translateX(' + translated + 'px)';
});

slideout.on('beforeopen', function () {
  fixed.style.transition = 'transform 300ms ease';
  fixed.style.transform = 'translateX(256px)';
  $('.hamburger').addClass('h-active');
});

slideout.on('beforeclose', function () {
  fixed.style.transition = 'transform 300ms ease';
  fixed.style.transform = 'translateX(0px)';
  $('.hamburger').removeClass('h-active');
});

slideout.on('open', function () {
  fixed.style.transition = '';
});

slideout.on('close', function () {
  fixed.style.transition = '';
});

}


function currentNav(){
  if($(".active-nav").length){
   var $currentPage = $(".nav-left .nav > li.active-nav > a").offset().left  - $(".nav-left").offset().left || 0;
 }
  $(".nav-active").css({
    left: $currentPage+ 16 +"px",
    width: $(".nav-left .nav > li.active-nav > a").width() || 0
  });
}


  currentNav();

$(window).on('resize', function(event) {
   currentNav();
});

$(".main-nav.nav-left > ul > li >a").hover(function() {
 var $this = $(this),
      $pos = $this.offset().left,
      $width = $this.width(),
$activePos = ($pos - $(".nav-left").offset().left);
  $(".nav-active").css({
    left: $activePos+ 16 +"px",
    width: $width
  });
},function(){
  currentNav();
});



function tabActive(){
  $(".tabs").each(function(index, el) {
      var $this = $(this);
     var $tabsActive = $this.find(".tabs-active");
      var $currentTab = $this.find("ul > li.currentTab a").offset().left - $this.offset().left;



      $tabsActive.css({
          left: $currentTab +"px",
          width: $this.find("ul > li.currentTab a").outerWidth()
      });
  });

}

if($(".tabs").length){
  tabActive();
  $(window).on('resize', function(event) {
    tabActive();
  });

}

$('.tabs ul li.currentTab').addClass('curr');

$(".tabs ul li a").on('click', function(event) {
  $(this).parent().addClass('curr').siblings().removeClass("curr");
  $(this).parent().addClass("currentTab").siblings().removeClass("currentTab");
  tabActive();
});

$(".tabs ul li a").hover(function(event) {
  $(this).parent().addClass("currentTab").siblings().removeClass("currentTab");
  tabActive();
}, function(){
  $(".curr").addClass('currentTab').siblings().removeClass('currentTab');
  tabActive();
});


// svg services

// $(".srvimg-box").each(function(index, el) {
//   var $url = $(this).data("srvimg");
//   var id = $(this).attr("id");
//   var dd = new Vivus($(this).attr("id"), {duration: 100, file: $url});
// });
var lg = new Vivus('svglogo', {duration: 50});
$(".header-main .brand").hover(function() {
   lg.stop().reset().play();
}, function() {
  // lg.reset();
});


$(".tabs ul > li a").on('click', function(event) {
   var $openTab = $(this).data("tabbox");
   var $this = $(this);
   var $tabsContainer =  $this.parents(".tabs").next(".tabs-content");
   var $tabsContent = $this.parents(".tabs").next().find(".tabs-inner");
   var $activeTabsContent = $this.parents(".tabs").next().find($openTab);


    $activeTabsContent.siblings().removeClass("tc-active");
    $activeTabsContent.addClass("tc-active");

    $tabsContent.hide();
    $activeTabsContent.fadeIn(300);

});


/// up down arrow
$(".up-down .ud-up").on('click', function(event) {
    $("html,body").animate({scrollTop: 0}, 1000);
});
$(".up-down .ud-down").on('click', function(event) {
    $("html,body").animate({scrollTop:$(document).height()-$(window).height()}, 1000);
});


// workbox animate
// $(".workbox").hover(function() {
//  $(this).addClass('wb-active');
// }, function() {
//   $(this).removeClass('wb-active');
// });

$(document).on({
    mouseenter: function () {
        $(this).addClass('wb-active');
    },
    mouseleave: function () {
       $(this).removeClass('wb-active');
    }
}, '.workbox');

// makes the parallax elements
function parallaxIt() {
  // create variables
  var $fwindow = $(window);
  var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

  var $contents = [];
  var $backgrounds = [];

  // for each of content parallax element
  $('[data-type="content"]').each(function(index, e) {
    var $contentObj = $(this);

    $contentObj.__speed = ($contentObj.data('speed') || 1);
    $contentObj.__fgOffset = ($contentObj.data('offset') || $contentObj.offset().top);
    $contents.push($contentObj);
  });

  // for each of background parallax element
  $('[data-type="background"]').each(function() {
    var $backgroundObj = $(this);

    $backgroundObj.__speed = ($backgroundObj.data('speed') || 1);
    $backgroundObj.__fgOffset = ($contentObj.data('offset') || $contentObj.offset().top);
    $backgrounds.push($backgroundObj);
  });

  // update positions
  $fwindow.on('scroll resize', function() {
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    $contents.forEach(function($contentObj) {
      var yPos = $contentObj.__fgOffset - scrollTop / $contentObj.__speed;

      $contentObj.css('transform', "translateY("+ -yPos+"px)");
      //console.log("translateY("+ yPos+"px)");
    })

    $backgrounds.forEach(function($backgroundObj) {
      var yPos = -((scrollTop - $backgroundObj.__fgOffset) / $backgroundObj.__speed);

      $backgroundObj.css({
        backgroundPosition: '50% ' + yPos + 'px'
      });
    });
  });

  // triggers winodw scroll for refresh
  $fwindow.trigger('scroll');
};

parallaxIt();




var cl = $(".main-nav .nav").children().not(".logo").clone(true)

$('#menumob').append(cl).wrapInner('<ul></ul>').find(".btn-primary").addClass('btn-primary-reverse');

  // mobile menu
  $('.hamburger').click(function (event) {
    $(this).toggleClass('h-active');
    slideout.toggle();
  });


function fixedHeader(){
	var $winPos = $(window).scrollTop();
	var $header = $(".header-main");
    if($winPos > 30){
    	$header.addClass('header-active');
    }else{
    	$header.removeClass('header-active');
    }
}

fixedHeader();

// fixed header
$(window).scroll(function(event) {
     fixedHeader();
});


$(function () {
    $(window).scroll(function () {
      if ($(this).scrollTop() > 2500) {
        $('.gotop').fadeIn();
      } else {
        $('.gotop').fadeOut();
      }
    });

    // scroll body to 0px on click
    $('.gotop').click(function () {
      $('body,html').animate({
        scrollTop: 0
      }, 800);
      return false;
    });
  });









});
